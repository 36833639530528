<template>
  <div>
    <v-card
      :loading="carregandoBuscar"
      elevation="0"
    >
      <v-toolbar
        flat
        dense
        color="grey lighten-3"
      >
        <v-toolbar-title>
          # {{ empresa.id || '--' }}
        </v-toolbar-title>
      </v-toolbar>
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="descricao"
                >
                  <v-text-field
                    v-model="empresaNova.descricao"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Descrição"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="8"
                md="4"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="imagem"
                >
                  <v-file-input
                    v-model="empresaNova.imagem"
                    truncate-length="15"
                    outlined
                    dense
                    show-size
                    accept="image/*"
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Imagem"
                    prepend-icon=""
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                v-if="empresaNova.imagem_logo"
                cols="4"
                md="1"
              >
                <v-avatar
                  size="40"
                  tile
                >
                  <v-img
                    eager
                    :src="caminhoImagens + empresaNova.imagem_logo"
                  />
                </v-avatar>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="logradouro"
                >
                  <v-text-field
                    v-model="empresaNova.logradouro"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Logradouro"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="complemento"
                >
                  <v-text-field
                    v-model="empresaNova.complemento"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Complemento"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="bairro"
                >
                  <v-text-field
                    v-model="empresaNova.bairro"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Bairro"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="numero"
                >
                  <v-text-field
                    v-model="empresaNova.numero"
                    v-mask="'######'"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Número"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col
                cols="6"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="cep"
                >
                  <v-text-field
                    v-model="empresaNova.cep"
                    v-mask="'#####-###'"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="CEP"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <autocomplete-estados
                  v-model="estadoId"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="cidade_id"
                >
                  <autocomplete-cidades
                    v-model="empresaNova.cidade_id"
                    :estado-id="estadoId"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-subheader class="h-auto pl-2">
                  Dados de integração com Cortex
                </v-subheader>
                <v-divider class="m-0"></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  vid="cortex_email"
                >
                  <v-text-field
                    v-model="empresaNova.cortex_email"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Cortex Email"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  vid="cortex_usuario"
                >
                  <v-text-field
                    v-model="empresaNova.cortex_usuario"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Cortex Usuário"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  vid="cortex_senha"
                >
                  <v-text-field
                    v-model="empresaNova.cortex_senha"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Cortex Senha"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-subheader class="h-auto pl-2">
                  Dados de integração com a Polícia Rodoviária Federal
                </v-subheader>
                <v-divider class="m-0"></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  vid="prf_nome_empresa"
                >
                  <v-text-field
                    v-model="empresaNova.prf_nome_empresa"
                    label="Nome de Empresa cadastrado"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="6">
                <validation-provider
                  v-slot="{ errors }"
                  vid="prf_chave_empresa"
                >
                  <v-text-field
                    v-model="empresaNova.prf_chave_empresa"
                    label="Chave de Autorização"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4">
                <validation-provider
                  v-slot="{ errors }"
                  vid="prf_url_integracao"
                >
                  <v-text-field
                    v-model="empresaNova.prf_url_integracao"
                    label="URL de integração "
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="8">
                <validation-provider
                  v-slot="{ errors }"
                  vid="prf_token_autorizacao"
                >
                  <v-text-field
                    v-model="empresaNova.prf_token_autorizacao"
                    label="Token de autorização (Header Authorization Token)"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4"/>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            class="text-left"
          >
            <btn-salvar
              v-if="$temPermissao('empresa-alteracao') || $temPermissao('empresa-insercao')"
              :carregando="carregandoSalvar"
              @click="$emit('salvar', empresaNova)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
    AutocompleteEstados: () => import('@/components/input/InputAutocompleteEstados'),
    AutocompleteCidades: () => import('@/components/input/InputAutocompleteCidades'),
  },

  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {}
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
    carregandoBuscar: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      caminhoImagens: process.env.VUE_APP_URL_SERVIDOR + 'storage/imagens_empresas/',
      estadoId: null,
      permissoes: {},
      permissoesSelecionadas: [],
      empresaNova: {
        descricao: null,
      },
    }
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return

      return this.$refs.observer.setErrors(val)
    },

    empresa(empresa) {
      if (_.isEmpty(empresa)) return false

      this.estadoId = empresa.endereco.cidade.estado_id

      let enderecoSemID = { ...empresa.endereco };
      delete enderecoSemID.id;

      empresa = {...empresa, ...enderecoSemID}

      delete empresa.endereco
      delete empresa.cidade

      this.empresaNova = empresa
    },
  },

  methods: {},
}
</script>
